import TableListJadwal from '@/views/transactions/delivery-item/delivery-pending/detail/TableListJadwal.vue'
import {
  ref,
  onMounted,
  getCurrentInstance,
  onBeforeMount
} from 'vue'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'

export default {
  name: 'DeliveryPendingDetail',
  components: {
    TableListJadwal
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const id = ref(null) as any
    const showDialogDetail = ref(false)
    const filters = {
      expand: '$expand=AgenAsal($expand=Kota($select=Nama); $select=Nama, Id), AgenTujuan($select=Nama, Id; $expand=Kota($select=Nama)), TransPengirimanDetails($count=true;$select=id)'
    }
    const dataFetch = ref({})

    const getDataSpecific = async () => {
      const response = await deliveryPendingUseCase.getDataForm(id.value, filters)
      dataFetch.value = response.result
      store.dispatch('hideLoading')
    }

    const showDialog = () => {
      showDialogDetail.value = true
    }

    // onBeforeMount(() => {})
    onMounted(() => {
      store.dispatch('showLoading')
      id.value = route.params.id
      if (id.value) {
        getDataSpecific()
      }
    })
    return {
      store,
      router,
      route,
      dataFetch,
      showDialogDetail,
      showDialog
    }
  }
}